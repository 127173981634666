/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import Vue from 'vue'
// import App from '../components/App.vue'

// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     render: h => h(App)
//   }).$mount()
//   document.body.appendChild(app.$el)

//   console.log(app)
// })


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//

import Vue from 'vue/dist/vue.esm'

import VueResource from 'vue-resource'
Vue.use(VueResource)

import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import en from '../locales/en.json'
import fr from '../locales/fr.json'

const translations = {
  "en": en,
  "fr": fr
};

import { mapInit } from '../plugins/map'
import { LimitCheckBoxes } from '../plugins/form'

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('map')) {
    mapInit();
  };

  LimitCheckBoxes();

  const i18n = new VueI18n({
    locale: document.getElementsByTagName('html')[0].getAttribute('lang'), // Set locale from <html> tag
    fallbackLocale: 'fr',
    messages: translations
  });

  if(document.getElementById('activity__book-card')){
    let activityId = document.querySelector('#activity__book-card').getAttribute('activityId');

    const appBookCard = new Vue({
      el: '#activity__book-card',
      render(h){
        return h(require('../containers/BookCard/').default,{
          props: {
            activityId
          }
        })
      },
      i18n
    })
  }

  if(document.getElementById('activity__book-card--mobile')){
    let activityId = document.querySelector('#activity__book-card--mobile').getAttribute('activityId');

    const appBookCard = new Vue({
      el: '#activity__book-card--mobile',
      render(h){
        return h(require('../containers/BookCard/').default,{
          props: {
            activityId
          }
        })
      },
      i18n
    })
  }

  if(document.getElementById('book-page')){
    let activityId = document.querySelector('#book-page').getAttribute('activityId');
    let slotId = document.querySelector('#book-page').getAttribute('slotId');
    let date = document.querySelector('#book-page').getAttribute('date');
    let bookingId = document.querySelector('#book-page').getAttribute('bookingId');
    let cgvUrl = document.querySelector('#book-page').getAttribute('cgvUrl');
    let stripePk = document.querySelector('#book-page').getAttribute('stripePk');

    const appBookPage = new Vue({
      el: '#book-page',
      // render: h => h(require('../containers/BookPage/').default)
      render(h){
        return h(require('../containers/BookPage/').default,{
          props: {
            activityId,
            slotId,
            date,
            bookingId,
            cgvUrl,
            stripePk
          }
        })
      },
      i18n
    })
  }
})
