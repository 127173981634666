<template>
  <div class="position-relative">
    <div class="book__overall-loader d-none"></div>

    <div class="container mt-4 mt-md-5">
      <div class="row justify-content-center">
        <div class="col-xxl-10">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto mt-3">
              <h1 class="book__title">{{ $t('bookpage.title') }}</h1>
            </div>

            <div class="col-auto mt-3">
              <a :href="bookingInfos ? bookingInfos.language_switch.url : ''" class="button-primary-light button-xs">
                <img :src="require('images/icons/switch-arrows.svg')">

                {{bookingInfos && bookingInfos.language_switch.lang == 'en' ? 'View this page in English' : 'Voir cette page en Français'}}
              </a>
            </div>
          </div>

          <div class="row row-40 mt-4 mt-md-5" v-if="true">
            <div class="col-lg-8 col-md-7">
              <ActivityRecap :activity="activityInfos" :date="date" :availability="availability" :bookingInfos="bookingInfos" v-if="activityInfos && bookingInfos" />

              <ActivityRecapSkeleton v-else />

              <div class="book__travelers mt-5">
                <h2>{{ $t('bookpage.what_are_your_choices') }}</h2>

                <div class="row row-10 mt-2">
                  <div class="col-auto">
                    <p class="custom-label-like" style="margin-top:2px;">
                      {{ $t('bookpage.personnalize_your_booking') }}
                    </p>
                  </div>
                </div>

                <div class="row mt-4" v-if="activityInfos && bookingInfos">
                  <div class="col-lg-4 col-md-6 mb-4" v-for="rate in activityInfos.rates">
                    <RateCountSelect :rate="rate" :value="bookingInfos.booking_activity_rates_attributes.find(r => r.activity_rate_id === rate.id).pax_count" :min_pax="0" :is_max="is_maxed_count" @incrementCount="incrementCount(rate.id)" @decrementCount="decrementCount(rate.id)" />
                  </div>
                </div>

                <div class="row mt-4" v-else>
                  <div class="col-lg-4 col-md-6 mb-4">
                    <RateCountSelectSkeleton />
                  </div>
                </div>
              </div>

              <div class="mt-4 book__more-infos">
                <div class="row row-20 align-items-end">
                  <div class="col-lg-auto">
                    <h2>{{$t('bookpage.transmit_info_to_host')}}</h2>
                  </div>

                  <div class="col-lg-auto mt-2 mt-lg-0">
                    {{$t('bookpage.optional')}}
                  </div>
                </div>

                <textarea class="custom-textarea mt-3 mb-0" rows="5" :placeholder="$t('bookpage.you_can_mention_here')" v-model="traveler_message">

                </textarea>

                <div class="row row-20 align-items-end mt-4 pt-3">
                  <div class="col-auto">
                    <h2>{{$t('bookpage.your_departure_date')}}</h2>
                  </div>

                  <div class="col-auto mt-2 mt-lg-0">
                    {{$t('bookpage.optional')}}
                  </div>
                </div>

                <div class="row row-15 align-items-center">
                  <div class="col-xl-4 col-lg-5 col-md-7 col-sm-6 col-10 mt-3">
                    <flatPickr class="custom-input custom-input-date mb-0" :config="flatPickrConfigs" :placeholder="$t('bookcard.date.choose_date')" v-model="bookingInfos.departure_date" @input="updateBooking" v-if="bookingInfos" />

                    <div class="book__skeleton" v-else >
                      <div class="book__pax-select">
                        <div class="row row-10 align-items-center">
                          <div class="col">
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-8 mt-sm-3 mt-2" v-if="bookingInfos">
                    <p class="custom-label-like mt-0">
                      {{$t('bookpage.your_departure_date_infos')}}
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="bookingInfos && bookingInfos.stripe_client_secret">
                <PaymentStripe
                  ref="PaymentStripe"
                  :clientSecret="bookingInfos.stripe_client_secret"
                  :return_url="bookingInfos.return_url"
                  :stripe_pk="stripePk"
                  @handleStripeLoading="handleStripeLoading" />
              </div>
            </div>

            <div class="col-lg-4 col-md-5 col-sm-8 book__summary__wrapper mt-5 mt-md-0 mb-5 mb-md-0 mx-auto">
              <div class="sticky">
                <Summary :activity="activityInfos" :date="date" :totals="bookingInfos.totals" :availability="availability" :loading="loading" :booking="bookingInfos" :coupon="coupon" :validCoupon="valid_coupon" @validateCoupon="validateCoupon" @cancelCoupon="cancelCoupon" v-if="activityInfos && availability && bookingInfos.totals" />

                <SummarySkeleton v-else />

                <div class="mt-4 d-none">
                  <TourcribHelp />
                </div>
                
                <a href="https://g.page/tourcrib" target="_blank" class="hover-trans">
                  <img :src="require('images/logos/google-badge.svg')" class="d-block mx-auto mt-4 pt-3">
                </a>
              </div>
            </div>

            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-sm-auto">
                  <button class="button-secondary mt-md-5 mb-md-5 mx-auto d-block d-md-inline-block" :class="{'loading':loading, 'disabled':total_count <= 0}" @click="stripePay">{{$t('bookpage.send_booking_request')}}</button>
                </div>

                <div class="col-sm my-4 book__cgv text-center text-sm-start">
                  <p><span v-html="$t('bookpage.click_on_button')"></span> <a class="link-primary hover-trans" target="_blank" :href="cgvUrl">{{$t('bookpage.cgv')}}</a></p>
                </div>
              </div>
            </div>
          </div>

          <div class="py-5 mt-5 text-center" v-else>
            <img :src="require('images/loader.svg')">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>  
  import debounce from 'debounce'

  import flatPickr from 'vue-flatpickr-component';
  import {French} from 'flatpickr/dist/l10n/fr.js';

  import ActivityRecap from './components/ActivityRecap'
  import ActivityRecapSkeleton from './components/ActivityRecap-skeleton'

  import Summary from './components/Summary'
  import SummarySkeleton from './components/Summary-skeleton'

  import RateCountSelect from './components/RateCountSelect'
  import RateCountSelectSkeleton from './components/RateCountSelect-skeleton'

  import HowItWorks from './components/HowItWorks'
  import TourcribHelp from './components/TourcribHelp'

  import PaymentStripe from './components/PaymentStripe';

  const fetchBookingUrl = "/api/v1/bookings/show/";
  const fetchActivityUrl = "/api/v1/activities/";
  const fetchUserCardsUrl = "/api/v1/user/cards";
  
  const validateCouponUrl = "/api/v1/bookings/validate_coupon";

  const updateBookingRatesCountsUrl = "/api/v1/bookings/update_booking_rates_counts"
  
  const updateBookingUrl = "/api/v1/bookings/update";

  export default {
    components: {flatPickr, ActivityRecap, ActivityRecapSkeleton, Summary, RateCountSelect, RateCountSelectSkeleton, HowItWorks, TourcribHelp, PaymentStripe, SummarySkeleton},
    props:['activityId', 'slotId', 'date', 'bookingId', 'cgvUrl', 'stripePk'],
    data () {
      return {
        activityInfos: null,
        bookingInfos: null,

        flatPickrConfigs: {
          dateFormat: 'Y-m-d',
          altFormat: 'D d M Y',
          // altFormat: 'd/m/Y',
          altInput: true,
          locale: this.$i18n.locale == 'fr' ? French : null,
          disableMobile: "true",
          minDate: 'today'
        },

        departure_date: null,

        rate_change_count: 0,

        user_cards:[],

        selected_card_id: null,
        pay_with_new_card: false,
        new_card_data: null,

        traveler_message: '',

        coupon_code: '',
        coupon: '',
        valid_coupon: '',

        overall_loading: true,
        stripe_loading: true
      }
    },
    watch: {
      rate_change_count: {
        handler(val){
          this.triggerLoading()

          this.updateBookingRatesCountsDebounced()
        },
        deep: true
      }
    },
    computed : {
      is_maxed_count () {
        if(this.activityInfos)
          return this.total_count == this.activityInfos.max_pax
      },
      availability() {
        if(this.bookingInfos){
          return {
            start: this.bookingInfos.date_start,
            end: this.bookingInfos.date_end
          }
        }
      },
      total_count() {
        if(this.bookingInfos){
          return this.bookingInfos.booking_activity_rates_attributes.reduce((accum,rate) => accum + rate.pax_count, 0)
        }
      },
      loading() {
        return this.overall_loading || this.stripe_loading
      }
    },
    created () {
      this.updateBookingRatesCountsDebounced = debounce(this.updateBookingRatesCounts, 500)
    },
    mounted () {
      this.fetchBookingInfos()
      this.fetchActivityInfos()
      // this.fetchUserCards()
    },
    methods : {
      fetchBookingInfos () {
        this.$http.get(fetchBookingUrl + this.bookingId + `?locale=${this.$i18n.locale}`).then(response => {
          // get body data
          let data = response.data.result;                                 

          this.bookingInfos = data;

          this.cutLoading()
        }, response => {
          console.log("Error in fetching booking");
        });
      },
      fetchActivityInfos () {
        this.$http.get(fetchActivityUrl + this.activityId + `?locale=${this.$i18n.locale}`).then(response => {
          // get body data
          let data = response.data.result;

          this.activityInfos = data;
        }, response => {
          console.log("Error in fetching activity infos");
        });
      },
      fetchUserCards () {
        this.$http.get(fetchUserCardsUrl + `?locale=${this.$i18n.locale}`).then(response => {
          // get body data
          let data = response.data.results;

          this.user_cards = data;
          
          this.selectFirstCard();
        }, response => {
          console.log("Error in fetching user cards");
        });
      },
      updateBookingRatesCounts () {
        this.$http.post(updateBookingRatesCountsUrl + `?locale=${this.$i18n.locale}`, {booking:{
          id: this.bookingId,
          booking_activity_rates_attributes: this.bookingInfos.booking_activity_rates_attributes
        }}).then(response => {
          // get body data
          let data = response.data;

          if(data?.redirect_url){
            window.location.replace(data.redirect_url);
          }

          this.bookingInfos.totals = data.results

          this.cutLoading()
        }, response => {
          console.log("Error in updating booking activity rates");
        });
      },
      validateCoupon (coupon_code) {
        this.coupon_code = coupon_code

        this.triggerLoading();

        this.$http.get(validateCouponUrl + `?locale=${this.$i18n.locale}`, {params:{
          coupon_code: this.coupon_code,
        }}).then(response => {
          this.coupon = ""
          
          // get body data
          let data = response.data.results;

          this.coupon = data
          
          if(this.coupon.valid){
            this.fetchTotalsDebounced()
            this.valid_coupon = this.coupon
          }else{
            this.cutLoading()
          }
        }, response => {
          console.log("Error in validating coupon");
        });
      },
      cancelCoupon(){
        this.coupon_code = ""

        this.triggerLoading()

        this.fetchTotalsDebounced(function(){
          this.coupon = ""
          this.valid_coupon = ""
        })
      },
      updateBooking () {
        this.$http.post(updateBookingUrl + `?locale=${this.$i18n.locale}`, {booking:{
          booking_id: this.bookingId,
          traveler_message: this.traveler_message,
          departure_date: this.bookingInfos.departure_date,
        }}).then(response => {
          let data = response.data.results;
        }, response => {
          console.log("Error in creating booking");
        });
      },
      stripePay () {
        this.updateBooking()
        this.$refs.PaymentStripe.pay();
      },
      incrementCount (id) {
        let rate = this.bookingInfos.booking_activity_rates_attributes.find(r => r.activity_rate_id === id);
        rate.pax_count += 1

        this.rate_change_count += 1
      },
      decrementCount (id) {
        let rate = this.bookingInfos.booking_activity_rates_attributes.find(r => r.activity_rate_id === id);
        
        if(rate.pax_count > 0)
          rate.pax_count -= 1
        
        this.rate_change_count += 1
      },
      selectCard (id) {
        this.selected_card_id = id
      },
      newCard (bool) {
        this.pay_with_new_card = bool
      },
      updateNewCardData (data) {
        this.new_card_data = data
      },
      selectFirstCard () {
        if(this.user_cards.length){
          this.selectCard(this.user_cards[0].id)
        }else{
          this.pay_with_new_card = true
        }
      },
      handleStripeLoading (value) {
        this.stripe_loading = value
      },
      triggerLoading () {
        this.overall_loading = true
      },
      cutLoading () {
        this.overall_loading = false
      }
    }
  }
</script>

<style>

</style>