<template>
  <div class="book-card__phone__wrapper">
    <label class="mt-0">{{ $t('bookcard.book_by_phone', {host:activityInfos.host.display_name}) }}</label>
    
    <div class="book-card__phone" :class="{'active':open}">
      <div class="row row-0">
        <div class="col input-wrap">
          <input type="text" :value="displayedPhone" readonly="true" @click="revealPhone">
        </div>

        <div class="col-auto">
          <a class="button-secondary-outline button-xs" :href="'tel:' + displayedPhone" v-if="open">
            {{ $t('bookcard.phone.call') }}
          </a>

          <button class="button-secondary button-xs" @click="revealPhone" v-else>
            {{ $t('bookcard.phone.display_phone') }}
          </button>
        </div>
      </div>
    </div>

    <a @click="contactWhatsapp" :href="`https://api.whatsapp.com/send?lang=fr&phone=${activityInfos.host.phone.replaceAll(' ', '').replaceAll('+', '')}&text=${encodeURIComponent($t('bookcard.whatsapp_intro', {host:activityInfos.host.display_name, activity_title: activityInfos.title}))}`" target="_blank" class="button-whatsapp button-xs w-100 mt-3">
      <img :src="require('images/logos/wa-logo.svg')" alt="">
      {{$t('bookcard.book_via_whatsap')}}
    </a>
  </div>
</template>

<script>
  const remindBookingUrl = "/api/v1/activities/contact/";

  export default {
    props: ['activityInfos'],
    data () {
      return {
        open: false,
        contacted: false,
        displayedPhone: this.activityInfos.host.phone.slice(0, 10) + ' ...'
      }
    },
    methods: {
      revealPhone(){
        this.open = true
        this.displayedPhone = this.activityInfos.host.phone
        if(this.contacted == false){
          this.contacted = true
          this.contact("phone")
        }
      },
      contactWhatsapp(){
        if(this.contacted == false){
          this.contacted = true
          this.contact("whatsapp")
        }
      },
      contact(medium){
        // Send call event to api
        this.$http.get(`${remindBookingUrl}${this.activityInfos.id}?medium=${medium}`).then(response => {
          // get body data
          let data = response.data.result;
        }, response => {
          console.log("Error");
        });
      }
    }
  }
</script>

<style lang="scss">
  
</style>