<template>
  <div class="book__skeleton">
    <div class="book__pax-select">
      <div class="row row-10 align-items-center">
        <div class="col">
          
        </div>

        <div class="col-auto">
          <button class="minus"></button>
        </div>

        <div class="col-auto">
          <button class="plus"></button>
        </div>
      </div>
    </div>

    <div>
      <div class="price d-flex align-items-end">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    }
  }
</script>

<style lang="scss" scoped>

</style>