<template>
  <div class="book-card__week mt-4 pt-2">
    <label class="mt-0">{{ $t('bookcard.week_slots') }}</label>

    <div class="week-scroll row row-0">
      <div class="col-auto" v-for="availability in availabilities">
        <div class="week-scroll__day">
          <span>{{ $t(`bookcard.weekdays.${availability.day}`) }}</span>

          <ul v-if="availability.slots.length > 0">
            <li v-for="slot in availability.slots">
              {{slot.start.replaceAll('00', '')}}
              <img :src="require('images/icons/caret-round.svg')" alt="">
              {{slot.end.replaceAll('00', '')}}
            </li>
          </ul>

          <div class="week-scroll__day__closed" v-else>
            {{ $t(`bookcard.closed`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['availabilities'],
    data () {
      return {
        
      }
    }
  }
</script>

<style lang="scss">
  
</style>