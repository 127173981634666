<template>
  <section class="book__stripe-payment mt-md-5 mt-4">
    <div class="row row-20 justify-content-between align-items-center">
      <div class="col-auto mt-3">
        <h2>{{ $t('bookpage.payment.title') }}</h2>
      </div>

      <div class="col-auto mt-3">
        <div class="book__stripe-payment__info">
          <div class="row row-10 align-items-center">
            <div class="col">
              {{ $t('bookpage.payment.payment_differed') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="book__stripe-payment__content mt-4" :class="{'error':error}">
      <StripeElementPayment
        ref="paymentRef"
        :pk="stripe_pk"
        :elements-options="elementsOptions"
        :confirm-params="confirmParams"
        :locale="$i18n.locale"
        @error="errorTrigger()"
        @loading="loading($event)"
        @element-ready="ready()"
        @element-change="change()" />

      <div class="d-flex justify-content-sm-end mt-3">
        <img :src="require('images/logos/stripe.svg')">
      </div>
    </div>
  </section>
</template>

<script>
  import { StripeElementPayment } from '@vue-stripe/vue-stripe';

  export default {
    components: {StripeElementPayment},
    props:['clientSecret', 'return_url', 'stripe_pk'],
    data () {
      return {
        error: false
      }
    },
    computed : {
      confirmParams() {
        return {
          return_url: this.return_url
        }
      },
      elementsOptions() {
        if(this.clientSecret){
          return {
            clientSecret: this.clientSecret,
            appearance: {
              variables: {
                fontFamily: 'Lato, system-ui, sans-serif',
                colorPrimary: '#1289A7',
                borderRadius: '10px'
              }
            },
          }
        }
      }
    },
    methods: {
      pay () {
        this.$refs.paymentRef.submit();
      },
      errorTrigger () {
        this.error = true
      },
      change () {
        this.error = false
      },
      loading (value) {
        this.$emit('handleStripeLoading', value)
        console.log(value);
      },
      ready () {
        this.$emit('handleStripeLoading', false)
      }
    }
  }
</script>

<style>

</style>