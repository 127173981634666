<template>
  <article class="book__summary book__skeleton">
    <div class="overlay-loading">
      <img :src="require('images/loader.svg')">
    </div>

    <h2></h2>

    <p class="mt-2"></p>
    
    <p class="mt-2"></p>

    <div>
      <hr>

      <div class="row row-0 justify-content-between subtotal">
        <div class="col">
          {{$t('bookpage.summary.subtotal')}}
        </div>

        <div class="col-auto">
          
        </div>
      </div>

      <div class="row row-0 justify-content-between total">
        <div class="col">
          {{$t('bookpage.summary.total')}}
        </div>

        <div class="col-auto">
          
        </div>
      </div>
    </div>

    <div>
      <hr>

      <h3>{{$t('bookpage.summary.cancellation_policy')}}</h3>

      <p class="mt-2" v-html="$t('bookpage.summary.cancellation_policy_desc')">

      </p>
    </div>
  </article>
</template>

<script>
  export default {
    data () {
      return {
        
      }
    }
  }
</script>

<style>

</style>