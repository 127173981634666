<template>
  <article class="book__summary" v-if="activity">
    <div class="overlay-loading" :class="{'active':loading}">
      <img :src="require('images/loader.svg')">
    </div>

    <h2>{{activity.title}}</h2>

    <p class="mt-2"><span>{{new Date(parseInt(date*1000)).toLocaleDateString('fr', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}}</span> - <span>de {{availability.start}} à {{availability.end}}</span></p>
    
    <p class="mt-2">{{booking.booking_activity_rates_attributes.filter(e => e.pax_count > 0).map(r => `${r.pax_count} x ${activity.rates.find(a => a.id === r.activity_rate_id).title}`).join(', ')}}</p>

    <form @submit.prevent="validateCoupon" class="coupon-form" :class="{'d-none':!coupon_entry}">
      <input class="custom-input" type="text" placeholder="Code promo" ref="coupon" :class="{'error':coupon_error}" @input="triggerNewTyping" v-model="coupon_code">

      <button type="button" class="button-primary button-xs" @click="validateCoupon" v-if="new_typing || !coupon.valid">{{$t('bookpage.summary.validate')}}</button>

      <small class="color-red d-block error-message" :class="{'on':coupon_error}">{{$t('bookpage.summary.invalid_coupon')}}</small>

      <small class="color-green d-block success-message" :class="{'on':totals.discount > 0 && !new_typing && !coupon_error}">{{$t('bookpage.summary.valid_coupon')}}</small>
    </form>

    <div v-if="false" :class="{'d-none':coupon_entry}">
      <hr>

      <span @click="triggerCouponEntry" class="link-primary hover-trans">{{$t('bookpage.summary.use_coupon')}}</span>
    </div>

    <div v-if="totals">
      <hr>

      <div class="row justify-content-between subtotal">
        <div class="col">
          {{$t('bookpage.summary.subtotal')}}
        </div>

        <div class="col-auto">
          {{formatMoney(totals.subtotal)}}
        </div>
      </div>

      <div class="row justify-content-between subtotal">
        <div class="col">
          {{$t('bookpage.summary.fees')}}
        </div>

        <div class="col-auto fw-bold color-green">
          {{$t('bookcard.prices.free')}}
        </div>
      </div>

      <div class="row justify-content-between discount" v-if="totals.discount > 0">
        <div class="col">
          <span class="d-inline-block me-1">{{$t('bookpage.summary.discount', {discount:validCoupon.amount})}}</span>

          <span @click="cancelCoupon" class="link-primary hover-trans">{{$t('bookpage.summary.delete')}}</span>
        </div>

        <div class="col-auto val">
          - {{formatMoney(totals.discount)}}
        </div>
      </div>

      <div class="row justify-content-between total">
        <div class="col">
          {{$t('bookpage.summary.total')}}
        </div>

        <div class="col-auto">
          {{formatMoney(totals.total)}}
        </div>
      </div>
    </div>

    <footer>
      <hr>

      <h3>{{$t('bookpage.summary.cancellation_policy')}}</h3>

      <p v-html="$t('bookpage.summary.cancellation_policy_desc')">

      </p>
    </footer>
  </article>
</template>

<script>
  export default {
    props: ['activity', 'date', 'pax_counts', 'totals', 'availability', 'loading', 'coupon', 'validCoupon', 'booking'],
    data () {
      return {
        coupon_entry: false,
        coupon_code: '',
        new_typing: false
      }
    },
    computed : {
      pax_count () {
        return this.pax_counts.adult + this.pax_counts.child + this.pax_counts.infant
      },
      coupon_error() {
        return this.coupon.valid == false && this.new_typing == false

        console.log(this.coupon_error);
      }
    },
    methods : {
      formatMoney(number) {
        return number.toLocaleString('fr', { style: 'currency', currency: 'EUR' });
      },
      triggerCouponEntry(){
        this.coupon_entry = true;
        
        setTimeout(() => {
          this.$refs.coupon.focus();
        }, 10)
      },
      triggerNewTyping(e){
        this.new_typing = true
        
        if(e.keyCode == 13){
          this.validateCoupon()
        }
      },
      validateCoupon(e){
        this.new_typing = false
        this.$emit('validateCoupon', this.coupon_code)
      },
      cancelCoupon(){
        this.coupon_code = ""
        this.new_typing = true
        this.$emit('cancelCoupon')
      }
    }
  }
</script>

<style>

</style>