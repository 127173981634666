<template>
  <div>
    <div class="book__pax-select">
      <div class="row row-10 align-items-center">
        <div class="col">
          {{`${value} x ${rate.title}`}}
        </div>

        <div class="col-auto">
          <button class="minus" :class="{'disabled':value <= 0 || value <= min_pax}" @click="$emit('decrementCount')"></button>
        </div>

        <div class="col-auto">
          <button class="plus" :class="{'disabled':is_maxed}" @click="$emit('incrementCount')"></button>
        </div>
      </div>
    </div>

    <div>
      <div class="price d-flex align-items-end" v-if="rate.price > 0">
        <span><strong>{{rate.price}} €</strong>/{{rate.title.toLowerCase()}}</span>
        
        <small class="ms-2 d-inline-block" v-if="rate.desc">({{rate.desc}})</small>
      </div>
    
      <span class="price fw-bold" v-else>{{$t('bookcard.prices.free')}}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['rate', 'value', 'is_max', 'min_pax'],
    data () {
      return {

      }
    },
    computed : {
      is_maxed () {
        return this.is_max || this.value >= this.rate.max_count
      }
    },
    mounted () {
      
    }
  }
</script>

<style lang="scss">

</style>