<template>
  <!-- <section class="activity__tourcrib-help text-center" style="margin:0; width:100%;">
    <img :src="require('images/icons/tourcrib-phone.svg')" alt="">

    <h6 class="mt-4">{{$t('bookpage.tourcrib_help.title')}}</h6>

    <p class="mt-3">{{$t('bookpage.tourcrib_help.subtitle')}}</p>

    <button class="button-white button-sm w-100 mt-4" data-bs-toggle="modal" data-bs-target="#chatBannerModal">
      <img :src="require('images/logos/wa-x-fbm.svg')" alt="">

      {{$t('bookpage.tourcrib_help.chat_with_us')}}
    </button>

    <a href="tel:+596696775526" class="mt-4 link-primary hover-trans">+596 696 77 55 26</a>
  </section> -->

  <section class="book__tourcrib-help">
    <div class="row row-20 align-items-center">
      <div class="col-auto d-md-none d-xl-block">
        <img :src="require('images/illus/book-tourcrib-help.png')" alt="">
      </div>

      <div class="col">
        <h4>{{$t('bookpage.tourcrib_help.title')}}</h4>

        <p>{{$t('bookpage.tourcrib_help.subtitle')}}</p>

        <a href="https://api.whatsapp.com/send?phone=596696775526" target="_blank" class="d-inline-flex align-items-center hover-trans">
          <img :src="require('images/logos/wa-logo.svg')" class="me-1" style="height:18px;">
          {{$t('bookpage.tourcrib_help.contact_us')}}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: [],
    data () {
      return {
      }
    }
  }
</script>

<style>

</style>