<template>
  <div class="book-card__notice mb-4 mb-md-0" :class="{'loading':loading}">
    <h4 class="uppercase">Covid 19</h4>

    <h5 class="mt-2">Confinement Martinique - Août 2021 </h5>

    <p class="mt-1">En raison du confinement, le fonctionnement de certaines activités risque d'être altéré.</p>

    <div class="form mt-3" v-if="false">
      <input type="email" class="custom-input no-sm mb-0" :class="{'is-invalid':email_invalid}" placeholder="nom@exemple.fr" v-model="email" @keyup="removeErrors">

      <span class="invalid-feedback d-inline-block mt-2 mb-0" v-if="email_invalid">
        L'adresse email est invalide
      </span>

      <button class="button-secondary button-xs uppercase" @click="submit" v-if="!loading">Ok</button>
    </div>

    <div class="overlay-loading" :class="{'active':loading}">
      <img :src="require('images/loader.svg')" v-if="!success && !error">

      <div v-if="success">
        <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><g fill="#1289A7" fill-rule="evenodd"><circle stroke="#1289A7" stroke-width="2" fill-opacity=".1" cx="25" cy="25" r="24"/><path d="M16.146 22.914l6.432 6.432L33.985 17.94l2.122 2.122-13.435 13.435-.069-.068-.093.093-8.485-8.485 2.121-2.122z"/></g></svg>

        <h6 class="mt-3">C'est noté !</h6>

        <p class="mt-2">Tu seras notifié aussitôt les demandes de réservation réactivées.</p>
      </div>

      <div v-if="error">
        <h6 class="color-red">Une erreur est survenue</h6>

        <p class="mt-2 color-red">Merci de réessayer plus tard</p>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce'

  const remindBookingUrl = "/api/v1/bookings/remind_me/";

  export default {
    props: ['activityId'],
    data () {
      return {
        email: '',
        email_invalid: false,
        loading: false,
        success: null,
        error: null
      }
    },
    created () {
      
    },
    methods: {
      submit() {
        if(this.validateEmail(this.email)){
          this.email_invalid = false

          this.triggerLoading()

          this.submitDebounced()
        }else{
          this.email_invalid = true
        }
      },
      triggerLoading() {
        this.loading = true
      },
      removeErrors() {
        this.email_invalid = false
      },
      remindSuccess() {
        this.success = true
      },
      remindFail() {
        this.error = true
      },
      validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        return re.test(String(email).toLowerCase());
      }
    }
  }
</script>

<style lang="scss">
  $primary:#1289A7;
  $secondary:#F5A623;
  $text-grey:#6B7C93;
  $text-dark-grey : #333333;

  .book-card__notice{
    padding:15px 15px 15px; background:rgba($primary, .1); border-radius:10px; color:$text-dark-grey; box-shadow:0 0 0 2px rgba($primary, .05) inset; position:relative;
    h4{font-size: 14px; color:$primary; font-weight: 600;}
    h5{font-size: 18px; color:$text-dark-grey; font-weight: 700;}
    p{font-size: 14px; line-height: 20px;}
    hr{border-width:2px; border-color:rgba($primary, .1); margin:15px 0;}
    .form{
      position:relative;
      input{padding-right:65px;}
      button{position:absolute; top:6px; right:6px;}
    }
    .overlay-loading{
      position:absolute; top:0; left:0; width:100%; height:100%; border-radius:10px; display:flex; align-items:center; justify-content: center; background:white; box-shadow:0 0 0 2px rgba($primary, .15) inset; opacity:0; visibility:hidden; pointer-events:none; transition:all .2s; padding:25px 30px; text-align:center;
      &.active{opacity:1; visibility:visible; pointer-events:initial;}
      h6{font-size:18px; font-weight:700; color:$primary;}
      p{font-size:16px; line-height: 22px; color:$text-grey;}
    }
  }
</style>