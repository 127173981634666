<template>
  <article class="book__activity-recap">
    <div class="row row-0">
      <div class="col-5 d-none d-lg-block">
        <figure :style="{backgroundImage:`url(${encodedThumbnailUrl})`}" ></figure>
      </div>

      <div class="col-lg-7">
        <div class="content">
          <h2>{{activity.title}}</h2>

          <h3 v-html="$t('bookpage.activity_recap.location_host', {city:activity.city, country: activity.country, host_name:activity.host.display_name})"></h3>

          <p class="d-flex align-items-center mt-3">
            <img :src="require('images/icons/activity/time.svg')" class="me-2" alt="">

            <span v-html="$t('bookpage.activity_recap.duration', {duration:activity.duration})"></span>
          </p>

          <p class="d-flex align-items-center mt-2 pt-1">
            <img :src="require('images/icons/activity/pax.svg')" class="me-2" alt="">

            <span v-html="$t('bookpage.activity_recap.pax_count', {max_pax:activity.max_pax})"></span>
          </p>

          <p v-if="activity.reviews.average > 0" class="d-inline-flex align-items-center activity__details__rating mt-3">
            <span class="d-inline-flex align-items-center">
              <!-- Full Stars -->
              <img v-for="i in fullStars" :key="'full' + i" :src="require('images/icons/activity/star.svg')" alt="">

              <!-- Half Star -->
              <img v-if="halfStars" :src="require('images/icons/activity/star-half.svg')" alt="">

              <!-- Empty Stars -->
              <img v-for="i in emptyStars" :key="'empty' + i" :src="require('images/icons/activity/star-empty.svg')" alt="">
              
              <span class="mt1 ms-2">{{activity.reviews.average}}/5 {{$t('bookpage.activity_recap.rating', {reviews_count: activity.reviews.count})}}</span>
            </span>
          </p>

          <p class="time-slot mt-3">
            {{$t('bookpage.activity_recap.time_slot', {datetime:new Date(date*1000).toLocaleDateString($i18n.locale, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }), time_start: availability.start, time_end:availability.end})}}
          </p>

          <p class="mt-2">
            {{bookingInfos.days_until_display}}
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
  export default {
    props: ['activity', 'date', 'availability', 'bookingInfos'],
    computed: {
      fullStars() {
        const rating = this.activity.reviews.average;
        return Math.floor(rating);
      },
      halfStars() {
        const rating = this.activity.reviews.average;
        return (rating - this.fullStars >= 0.5) ? 1 : 0;
      },
      emptyStars() {
        return 5 - this.fullStars - this.halfStars;
      },
      encodedThumbnailUrl() {
        // Fixes issue for thumbnail urls with single quotes
        return this.activity.thumbnail.replace("'", "%27");
      }
    }
  }
</script>

<style>

</style>