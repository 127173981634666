<template>
  <div class="book-card" v-if="activityInfos && hasAvailableSlots">
    <span class="back hide-bookcard d-md-block d-lg-none">
      {{ $t('bookcard.back_to_activity') }}
    </span>

    <h2>
      {{ $t('bookcard.title') }}<br>
      <span v-if="mode == 'Online'" class="mt-2 d-block fw-normal fs-6">{{ $t('bookcard.subtitle', {country: activityInfos.country}) }}</span>
    </h2>

    <div v-if="activityInfos.is_active">
      <Phone :activityInfos="activityInfos" v-if="mode == 'Phone'" />

      <div class="row row-50 mt-3 activity__prices">
        <div class="col-auto mt-3" v-for="rate in activityInfos.rates" :key="rate.id">
          <article>
            <h3>{{ rate.title }}</h3>

            <small>{{ rate.desc }}</small>

            <span v-if="rate.price > 0">{{rate.price}} €</span>

            <span class="small" v-else>{{ $t('bookcard.prices.free') }}</span>
          </article>
        </div>
      </div>

      <div class="book-card__phone-wrap" v-if="mode == 'Phone'">
        <WeekView :availabilities="activityInfos.availabilities" />
      </div>

      <div class="mt-4 pt-3 pt-sm-2 pt-md-0" v-if="false">
        <Notice :activityId="activityInfos.id" />
      </div>
      
      <div class="w-100" v-if="mode == 'Online'">
        <label class="mt-4 pt-2">{{ $t('bookcard.date.choose_a_date') }}</label>

        <div class="row row-20">
          <div class="col-sm-8">
            <flatPickr class="custom-input custom-input-date mb-0" :config="flatPickrConfigs" :placeholder="$t('bookcard.date.choose_date')" v-model="selectedDay" @input="selectFirstSlot" />
          </div>
        </div>

        <label for="">{{ $t('bookcard.date.choose_availability') }}</label>

        <div class="row row-15 slot-selects">
          <div class="col-6" v-for="(slot, index) in availableSlots" :key="index">
            <button :class="{active:selectedSlotId == slot.id}" @click="selectSlot(slot.id)">
              {{slot.start}} - {{slot.end}}

              <span class="selected" v-if="(selectedSlotId == slot.id)">{{ $t('bookcard.selected') }}</span>
            </button>
            
          </div>
        </div>
      </div>

      <div class="row row-20">
        <div class="col-auto mt-sm-4 mt-4" v-if="mode == 'Online'">
          <a class="button-secondary wide-paddings" data-turbolinks="false" rel="nofollow" v-if="dateSelectedDay" :href="`/${this.$i18n.locale}${activityInfos.book_url.replace('$1', selectedSlotId)}${selectedDay}`">{{ $t('bookcard.continue_booking') }}</a> <!-- #ToBetter : href internationalization is ugly done, could be better -->
        </div>

        <div class="col-auto mt-sm-4 mt-4 d-none d-md-block d-lg-none">
          <button class="button-white-bordered hide-bookcard">{{ $t('bookcard.close') }}</button>
        </div>
      </div>

      <footer v-if="mode == 'Online'">
        <p>{{ $t('bookcard.payment_secured') }}</p>

        <img :src="require('images/payment-methods.svg')" alt="">
      </footer>
    </div>

    <div class="pb-4 pt-3 lh-sm text-grey" v-else>
      Vous ne pouvez pas réserver cette activité en ce moment.
    </div>
  </div>
</template>

<script>
  import flatPickr from 'vue-flatpickr-component';
  import {French} from 'flatpickr/dist/l10n/fr.js';

  import moment from 'moment-timezone';

  import Notice from './components/Notice'
  import Phone from './components/Phone'
  import WeekView from './components/WeekView'
  
  const fetchActivityUrl = "/api/v1/activities/";

  const CONST_MIN_BOOK_DATE = 3;

  export default {
    name: 'BookCard',
    components: {flatPickr, Notice, Phone, WeekView},
    props:['activityId'],
    data () {
      return {
        selectedDay: moment().add(CONST_MIN_BOOK_DATE,'days').format('Y-M-D'),
        selectedSlotId: null,
        activityInfos: null,
        mode: 'Online', // Sets the bookCardMode | 'Online', 'Phone'
        flatPickrConfigs: {
          dateFormat: 'Y-m-d',
          altFormat: 'D d M Y',
          altInput: true,
          locale: this.$i18n.locale == 'fr' ? French : null,
          disableMobile: "true",
          // minDate: 'today',
          minDate: this.getMinDate(),
          enable: []
        }
      }
    },
    computed: {
      // selectedDay as Date object
      dateSelectedDay () {
        var date = moment(this.selectedDay, "YYYY-MM-DD").toDate();
        return date
      },

      hasAvailableSlots () {
        let has = false

        if(this.activityInfos){
          this.activityInfos.availabilities.some(function(a){
            if(a.slots.length > 0){
              has = true
            }
          });
        }

        return has
      },
      
      // All available Slots for the selectedDay
      availableSlots () {
        let dayString = this.getDayString(this.dateSelectedDay)
        let availability = this.activityInfos.availabilities.find(a => a.day === dayString)

        var slots = availability.slots.sort()

        return slots
      }
    },
    mounted() {
      this.fetchActivityInfos()
    },
    methods : {
      fetchActivityInfos () {
        this.$http.get(`${fetchActivityUrl}${this.activityId}` + `?locale=${this.$i18n.locale}`).then(response => {
          // get body data
          let data = response.data.result;

          this.activityInfos = data;

          // Check and set the minDate
          if (data.min_booking_date) {
            const minBookingDate = moment(this.activityInfos.min_booking_date).toDate();
            minBookingDate.setHours(0, 0, 0, 0);
            const threeDaysFromNow = new Date().fp_incr(CONST_MIN_BOOK_DATE);
            
            if (minBookingDate > threeDaysFromNow) {
              this.selectedDay = data.min_booking_date;
              this.flatPickrConfigs.minDate = minBookingDate;
            }
          }

          if(this.hasAvailableSlots){
            this.enableDates()
            this.selectNextAvailableDay()
            this.selectFirstSlot()
          }
        }, response => {
          console.log("Error");
        });
      },
      getMinDate() {        
        if (this.activityInfos && this.activityInfos.min_booking_date) {
          const minBookingDate = moment(this.activityInfos.min_booking_date).toDate();

          if (minBookingDate > new Date().fp_incr(CONST_MIN_BOOK_DATE)) {
            return minBookingDate;
          }
        }

        return new Date().fp_incr(CONST_MIN_BOOK_DATE);
      },
      // Enable dates on flatpickr
      enableDates () {
        if(this.activityInfos){
          let result = []
          this.activityInfos.availabilities.forEach((a, index) => {
            if(a.slots.length != 0){
              let weekdayNumber = this.getWeekdayNumber(a.day)
              result = [...result, weekdayNumber]
            }
          })

          this.flatPickrConfigs.enable = [
            function(date){
              return result.includes(date.getDay())
            }
          ]
        }
      },
      // Return the weekday number from weeday string (ex: Mon)
      getWeekdayNumber (weekday) {
        let number
        switch(weekday){
          case "mon":
            number = 1
            break;
          case "tue":
            number = 2
            break;
          case "wed":
            number = 3
            break;
          case "thu":
            number = 4
            break;
          case "fri":
            number = 5
            break;
          case "sat":
            number = 6
            break;
          case "sun":
            number = 0
        }
        return number
      },
      // Get 3 letter day (ex: Mon) from date
      getDayString (date) {
        return date.toString().substring(0, 3).toLowerCase()
      },
      // Number of days until the next day that has slots
      daysUntilNextDayWithSlots (date) {
        let dayString = this.getDayString(date)

        let index = this.activityInfos.availabilities.findIndex(a => a.day === dayString)
        index++

        let numberOfDays = 0

        for(var i = 0; i < this.activityInfos.availabilities.length; i++){
          numberOfDays++

          if(index == this.activityInfos.availabilities.length)
            index = 0

          if(this.activityInfos.availabilities[index].slots.length > 0)
            return numberOfDays

          index++
        }
        return false
      },
      // Select the next available Day
      selectNextAvailableDay () {
        var date = this.dateSelectedDay

        if(this.dayHasSlots(date)){
          this.selectedDay = moment(date).format('Y-M-D')
        }else{
          let daysUntilNextDayWithSlots = this.daysUntilNextDayWithSlots(date)
          
          let nextAvailableDate = date.setDate(date.getDate() + daysUntilNextDayWithSlots)
          this.selectedDay = moment(nextAvailableDate).format('Y-M-D')
        }
      },
      // Check if given day has Slots
      dayHasSlots (date) {
        let dayString = this.getDayString(date)
        let dayAvailabilities = this.activityInfos.availabilities.find(a => a.day === dayString)
        return dayAvailabilities.slots.length > 0
      },
      // Select a slot with its id (taken from the clicked button)
      selectSlot (id) {
        this.selectedSlotId = id
      },
      // Select the first slot when an available date is selected
      selectFirstSlot () {
        this.selectedSlotId = this.availableSlots[0].id
      }
    }
  }
</script>

<style lang="scss">
  
</style>