<template>
  <article class="book__activity-recap book__skeleton">
    <div class="row row-0">
      <div class="col-5 d-none d-lg-block">
        <figure ></figure>
      </div>

      <div class="col-lg-7">
        <div class="content">
          <h2></h2>

          <h3></h3>

          <p class="d-flex align-items-center mt-3">
            <img :src="require('images/icons/activity/time.svg')" class="me-2" alt="">

            <span></span>
          </p>

          <p class="d-flex align-items-center mt-2 pt-1">
            <img :src="require('images/icons/activity/pax.svg')" class="me-2" alt="">

            <span></span>
          </p>

          <p class="mt-3">
            
          </p>

          <p class="time-slot mt-3">
            
          </p>

          <p class="mt-2">
            
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
  export default {
    data () {
      return {
        
      }
    }
  }
</script>

<style>

</style>