const LimitCheckBoxes = () => {
  $('.check_boxes').click(function () {
    console.log($('.check_boxes:checked').length)
    if ($('.check_boxes:checked').length > 4) {
      alert("1 à 4 réponses possible(s)");
      this.checked = false;
    }
    else
      $(".check_boxes").not(":checked").removeAttr('disabled');
  });
}
  export { LimitCheckBoxes };
